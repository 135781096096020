<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t("Offer") }}</h1>
        </div>
      </div>
    </section>
    <!--SECTION START-->
    <section style="background-color: white;padding-top: 20px;">
      <div class="container ">
        <div >
          <img style="max-height: 400px;width: 100%;" :src="$baseUploadURL + item.image" />
        </div>

        <div >
          <div class="et_pb_section">
            <div class="et_pb_section_description" style="width: 100% !important;">
              <h2>{{ $getByLang(item.name) }}</h2>
              <p v-html="$getByLang(item.body)"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="
      display: none;
          background-color: white;
          padding-top: 150px;
          padding-bottom: 150px;
        ">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <form method="post" class="row">
              <div class="col-md-2">
                <div class="mb-3">
                  <label for="sexType" class="form-label">
                    {{ $t("Type") }}
                  </label>

                  <b-form-select v-model="body.sexType" class="mb-3">
                    <b-form-select-option value="MR">{{
            $t("MR")
          }}</b-form-select-option>
                    <b-form-select-option value="MRS">{{
              $t("MRS")
            }}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>

              <div class="col-md-5">
                <div class="mb-3">
                  <label for="fname" class="form-label">
                    {{ $t("First Name") }}</label>
                  <input type="text" class="form-control" id="fname" v-model="body.fname" />
                </div>
              </div>
              <div class="col-md-5">
                <div class="mb-3">
                  <label for="lname" class="form-label">{{
            $t("Last Name")
          }}</label>
                  <input type="text" class="form-control" id="lname" v-model="body.lname" />
                </div>
              </div>


              <div class="col-md-10">
                <div class="mb-3">
                  <label for="address" class="form-label">
                    {{ $t("AddressHome") }}
                  </label>
                  <input type="text" class="form-control" id="address" v-model="body.address" />
                </div>
              </div>
              <div class="col-md-2">
                <div class="mb-3">
                  <label for="zip" class="form-label">
                    {{ $t("zip") }}
                  </label>
                  <input type="number" class="form-control" id="zip" v-model.number="body.zip" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="packagesPricesId" class="form-label">
                    <!-- {{ $t('Price Plan') }} -->
                    Behandlungstyp
                    <!-- <span style="color:red">
                    {{ $t('per person') }}
                  </span> -->
                  </label>

                  <select class="custom-select" id="packagesPricesId" v-model.number="body.packagesPricesId">
                    <option v-for="itm of prices" :value="itm.id" :key="itm.price">{{ $getByLang(itm.name) }} ({{
            itm.price }})</option>
                  </select>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="city" class="form-label">
                    {{ $t("city") }}
                  </label>
                  <input type="text" class="form-control" id="city" v-model="body.city" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="country" class="form-label">
                    {{ $t("country") }}
                  </label>
                  <input type="text" class="form-control" id="country" v-model="body.country" />
                </div>
              </div>
            </form>
          </div>
        </div>
        <b-button block variant="danger" @click="add" style="background-color: #f26838; color: white">{{ $t("Confirm")
          }}</b-button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        name: null,
        body: null,
        id: null,
      },
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),
      body: {
        sexType: "MR",
        fname: null,
        lname: null,
        paymentMethod: "cash",
        address: null,
        zip: null,
        city: null,
        country: null,
        packagesId: null,
        packagesPricesId: null,
      },
      prices: [],
    };
  },

  created() {
    this.$http.get(`packages/1`).then(
      (res) => {
        if (res.data) {
          this.item = res.data.data;

          this.body.packagesId = res.data.data.id;
          this.prices = res.data.data.packagesPricesList;

          this.body.packagesPricesId =
            this.prices.length > 0 ? this.prices[0].id : null;
        }
      },
      (err) => {
        console.log(err);
      }
    );

    this.$http.post(`users/getProfile`, {}).then((res) => {
      const user = res.data.data;

      this.body.usersId = user.id;
      this.body.email = user.email;
      this.body.phone = user.phone;
      this.body.sexType = user.sexType;
      this.body.fname = user.fname;
      this.body.lname = user.lname;
      this.body.address = user.address;
      this.body.zip = Number(user.zip);
      this.body.city = user.city;
      this.body.country = user.country;
    });
  },
  methods: {
    add() {
      if (this.body.fname) {
        this.$eventHub.$emit("showSpinner", true);
        this.$http
          .post(`packages_users`, {
            ...this.body,
          })
          .then(
            (res) => {
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "success",
                title: this.$t("Success"),
                text:
                  this.body.paymentMethod == "cash"
                    ? this.$t("Success Done")
                    : this.$t("toBay"),
                showConfirmButton: false,
                timer: 1500,
              });

              if (this.body.paymentMethod == "cash") {
                this.$router.push("/");
              } else {
                const price = this.prices.find(
                  (prices) =>
                    Number(prices.id) == Number(this.body.packagesPricesId)
                );
                this.$router.push(
                  "/PaymentPackage/" + res.data.data.id + "/" + price.price
                );
              }
            },
            (err) => {
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "error",
                title: this.$t("Error"),
                text: this.$t("Something has gone wrong"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );
      } else {
        this.$swal.fire({
          icon: "error",
          title: this.$t("Error"),
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  head: {
    title: {
      inner: "Offers",
    },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>

<style></style>
